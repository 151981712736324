import React, { useCallback, useEffect, useState } from 'react';
import ordersIcon from '../../assets/img/menu-icon-1.webp';
import Order from '../module-tabs-detail/Order';
import axios from 'axios';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import ExportExcel from "../exports/Excelexport";
import Loader from '../common/Loader';
import _ from "lodash";

const Loading = () => {
    return (
        <>
            <div className="loader">
                <div className="square" id="sq1"></div>
                <div className="square" id="sq2"></div>
                <div className="square" id="sq3"></div>
                <div className="square" id="sq4"></div>
                <div className="square" id="sq5"></div>
                <div className="square" id="sq6"></div>
                <div className="square" id="sq7"></div>
                <div className="square" id="sq8"></div>
                <div className="square" id="sq9"></div>
            </div>
        </>
    )
}

const calculatePickupDateInfo = (deliveryDate) => {
    const deliveryDay = new Date(deliveryDate).getUTCDay();
    let pickupOffset = 0;
    switch (deliveryDay) {
        case 1:
            pickupOffset = 10;
            break;
        case 3:
            pickupOffset = 12;
            break;
        case 5:
            pickupOffset = 10;
            break;
        default:
            pickupOffset = 10;
            break;
    }
    const pickupDate = new Date(deliveryDate);
    while (pickupOffset > 0) {
        pickupDate.setDate(pickupDate.getDate() + 1);
        const dayOfWeek = pickupDate.getDay();
        if (dayOfWeek !== 0 && dayOfWeek !== 6) { // Skip Sundays (0) and Saturdays (6)
            pickupOffset--;
        }
    }
    return pickupDate.toUTCString(); // Convert to ISO format for consistency
};

function AllOrderTab() {

    const pathURL = process.env.REACT_APP_API_URL;
    // const [isLoading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalOrders, setTotalOrders] = useState(1);
    const [limit, setLimit] = useState(10);
    // const [orderStatus, setOrderStatus] = useState("intake");
    const [orderData, setOrderData] = useState([]);
    let orderStatus = "intake";
    const location = useLocation()

    const fetchCode = async () => {
        const { data } = await axios.get(`${pathURL}order/all?page=${currentPage}&limit=${limit}&status=${location.pathname.replace('/', '')}`);
        setTotalOrders(data?.pagination?.totalOrders)
        setTotalPages(data?.pagination?.totalPages)
        // console.log(data?.data)
        setOrderData(data?.data?.map(item => ({
            name: item.customer_id?.name,
            email: item.customer_id?.email,
            createdAt: item?.createdAt,
            status: item.status,
            itemCount: item.order_items.length,
            pickupDate: item.order_type == "Popup" ? new Date(item.createdAt).toLocaleDateString("en-US", { timeZone: "America/Los_Angeles" }) : new Date(item.collection_date).toLocaleDateString("en-US", { timeZone: "America/Los_Angeles" }),
            paymentStatus: "Completed",
            items: item?.order_items?.map(el => el.selectedType).join(", "),
            orderId: item._id,
            item: item,
            assign_status: item.assign_status
        })).reverse())
        return data;
    };
    // console.log(orderData)

    const { data, error, isLoading, refetch, isFetching } = useQuery({
        queryKey: ['fetchOrder'],
        queryFn: fetchCode,
    });

    const [searchTerm, setSearchTerm] = useState("");

    let [exportOrdrData, setexportOrdrData] = useState(null)
    console.log(exportOrdrData)
    const fetchExportData = async () => {
        if (location.pathname === "/intake") {
            orderStatus = "intake";
            setTimeout(() => {
                refetch();
            }, 500);
            const response = await axios.get(`${pathURL}order/allorderexcelsheet/intake`);
            setexportOrdrData(response?.data)
            return response.data;
        }

        if (location.pathname === "/picked") {
            orderStatus = "picked";
            setTimeout(() => {
                refetch();
            }, 500);
            const response = await axios.get(`${pathURL}order/allorderexcelsheet/picked`);
            setexportOrdrData(response?.data)
            return response.data;
        }

        if (location.pathname === "/withfixer") {
            orderStatus = "withfixer";
            setTimeout(() => {
                refetch();
            }, 500);
            const response = await axios.get(`${pathURL}order/allorderexcelsheet/withfixer`);
            setexportOrdrData(response?.data)
            return response.data;
        }

        if (location.pathname === "/delivered") {
            orderStatus = 'delivered';
            setTimeout(() => {
                refetch();
            }, 500);
            const response = await axios.get(`${pathURL}order/allorderexcelsheet/delivered`);
            setexportOrdrData(response?.data)
            return response.data;
        }

        if (location.pathname === "/fixedandwithfxry") {
            orderStatus = 'fixedandwithfxry';
            setTimeout(() => {
                refetch();
            }, 500);
            const response = await axios.get(`${pathURL}order/allorderexcelsheet/fixedandwithfxry`);
            setexportOrdrData(response?.data)
            return response.data;
        }

        if (location.pathname === "/readyfordelivery") {
            orderStatus = 'readyfordelivery';
            setTimeout(() => {
                refetch();
            }, 500);
            const response = await axios.get(`${pathURL}order/allorderexcelsheet/readyfordelivery`);
            setexportOrdrData(response?.data)
            return response.data;
        }

        if (location.pathname === "/fixedbutwithfixer") {
            orderStatus = 'fixedbutwithfixer';
            setTimeout(() => {
                refetch();
            }, 500);
            const response = await axios.get(`${pathURL}order/allorderexcelsheet/fixedbutwithfixer`);
            setexportOrdrData(response?.data)
            return response.data;
        }

        if (location.pathname === "/archived") {
            orderStatus = 'archived';
            setTimeout(() => {
                refetch();
            }, 500);
            const response = await axios.get(`${pathURL}order/allorderexcelsheet/archived`);
            setexportOrdrData(response?.data)
            return response.data;
        }

        if (location.pathname === "/onhold") {
            orderStatus = "onhold";
            refetch();
            const response = await axios.get(`${pathURL}order/allorderexcelsheet/onhold`);
            setexportOrdrData(response?.data)
            return response.data;
        }

        if (location.pathname === "/dashboard") {
            orderStatus = "dashboard";
            refetch();
            const response = await axios.get(`${pathURL}order/allorderexcelsheet/dashboard`);
            setexportOrdrData(response?.data)
            return response.data;
        }
    }

    useEffect(() => {
        setOrderData([]);
        setCurrentPage(1);
        fetchExportData()
    }, [location.pathname])


    const exportOrdersData = useQuery({
        queryKey: ['fetchExportData'],
        queryFn: fetchExportData,
    });
    const [filteredOrders, setFilteredOrders] = useState([])
    const [isFilteringHappening, setisFilteringHappening] = useState(false);
    // const handleSearch = async (searchTerm) => {
    //     if (searchTerm != '') {
    //         const res = await axios.get(`${pathURL}order/searchorders?searchTerm=${searchTerm}`)
    //         if (res.status == 200) {
    //             setFilteredOrders(res?.data?.orders?.map(item => ({
    //                 name: item.customer_id?.name,
    //                 email: item.customer_id?.email,
    //                 createdAt: item?.createdAt,
    //                 status: item.status,
    //                 itemCount: item.order_items.length,
    //                 pickupDate: item.order_type == "Popup" ? new Date(item.createdAt).toLocaleDateString("en-US", { timeZone: "America/Los_Angeles" }) : new Date(item.collection_date).toLocaleDateString("en-US", { timeZone: "America/Los_Angeles" }),
    //                 paymentStatus: "Completed",
    //                 items: item?.order_items?.map(el => el.selectedType).join(", "),
    //                 orderId: item._id,
    //                 item: item,
    //                 assign_status: item.assign_status
    //             })))
    //         } else {
    //             setFilteredOrders([]);
    //         }
    //     } else {
    //         setFilteredOrders([]);
    //     }
    // }


    // Debounced search function
    const handleSearch = useCallback(_.debounce(async (query) => {
        if (query.trim() !== "") {
            setisFilteringHappening(true);
            try {
                const res = await axios.get(`${pathURL}order/searchorders?searchTerm=${query}`);
                if (res.status === 200) {
                    setFilteredOrders(res.data.orders.map(item => ({
                        name: item.customer_id?.name,
                        email: item.customer_id?.email,
                        createdAt: item?.createdAt,
                        status: item.status,
                        itemCount: item.order_items.length,
                        pickupDate: item.order_type === "Popup" ?
                            new Date(item.createdAt).toLocaleDateString("en-US", { timeZone: "America/Los_Angeles" }) :
                            new Date(item.collection_date).toLocaleDateString("en-US", { timeZone: "America/Los_Angeles" }),
                        paymentStatus: "Completed",
                        items: item?.order_items?.map(el => el.selectedType).join(", "),
                        orderId: item._id,
                        item: item,
                        assign_status: item.assign_status
                    })));
                    setisFilteringHappening(false)
                } else {
                    setFilteredOrders([]);
                }
            } catch (error) {
                console.error("Error fetching search results:", error);
                setFilteredOrders([]);
            }
        } else {
            setFilteredOrders([]);
        }
    }, 500), []);

    useEffect(() => {
        if (searchTerm !== '') {
            handleSearch(searchTerm)
        } else {
            setFilteredOrders([])
        }
    }, [searchTerm]);

    const totalItems = orderData?.reduce((acc, curr) => acc + curr.itemCount, 0)

    const handlePageChange = (pageNumber) => {
        setTimeout(() => {
            refetch();
        }, 500);
        window.scrollTo({ top: 0, behavior: "smooth" });
        setOrderData([]);
        if (pageNumber > 0 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };

    useEffect(() => {
        refetch()
    }, [currentPage])

    const handleInputChange = (e) => {
        const value = e.target.value;
        setSearchTerm(value);
        handleSearch(value); // Call debounced function
    };

    return (
        <div className="tab-detail-outer-box">
            <h6 className='tab-detail-heading'> <span><img src={ordersIcon} alt="" /></span> All Orders</h6>
            <h6>Total Orders : {data?.pagination?.totalMainOrders}</h6>
            <h6>Total Sub Orders : {data?.pagination?.totalJobs}</h6>
            <h6>Total Orders Including Failed Payments : {!isLoading ? data?.pagination?.total : 'Loading ...'}</h6>
            {orderData && orderData.length == 0 &&
                <Loader />
            }
            {isFilteringHappening &&
                <Loader />
            }
            <div className="right-box flex-box align-center" style={{ display: "flex", justifyContent: "end", marginBottom: "0.8rem" }}>
                <input
                    type="text"
                    placeholder="Search Orders..."
                    className="search-field"
                    value={searchTerm}
                    // style={{textTransform : "uppercase"}}
                    onChange={handleInputChange}

                />
                {!isLoading && exportOrdrData != null && (
                    <ExportExcel filename={"Orders"} exceldata={exportOrdrData} />
                )}
            </div>
            <ul className='all-orders-listing'>
                {searchTerm == '' &&
                    orderData?.map((order, index) => (
                        <Order key={index} order={order} number={index} />
                    ))
                }
                {searchTerm != '' &&
                    filteredOrders?.reverse()?.map((order, index) => (
                        <Order key={index} order={order} number={index} />
                    ))
                }
            </ul>
            {searchTerm == '' &&
                <div style={{ textAlign: 'center', margin: '20px 0' }}>
                    <button
                        onClick={() => {
                            window.scrollTo({ top: 0, behavior: "smooth" });
                            handlePageChange(currentPage - 1)
                            setTimeout(() => {
                                refetch();
                            }, 500);
                        }}
                        disabled={currentPage === 1}
                        style={{
                            padding: '8px 16px',
                            backgroundColor: currentPage === 1 ? '#ddd' : '#007bff',
                            color: currentPage === 1 ? '#aaa' : '#fff',
                            border: 'none',
                            borderRadius: '4px',
                            cursor: currentPage === 1 ? 'not-allowed' : 'pointer',
                            marginRight: '10px',
                            transition: 'background-color 0.3s',
                        }}
                    >
                        Previous
                    </button>

                    <span style={{ fontSize: '16px', fontWeight: 'bold', margin: '0 10px' }}>
                        Page {currentPage} of {totalPages}
                    </span>

                    <button
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                        style={{
                            padding: '8px 16px',
                            backgroundColor: currentPage === totalPages ? '#ddd' : '#007bff',
                            color: currentPage === totalPages ? '#aaa' : '#fff',
                            border: 'none',
                            borderRadius: '4px',
                            cursor: currentPage === totalPages ? 'not-allowed' : 'pointer',
                            marginLeft: '10px',
                            transition: 'background-color 0.3s',
                        }}
                    >
                        Next
                    </button>
                </div>
            }
        </div>
    );
}

export default AllOrderTab;
