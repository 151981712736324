import * as FileSaver from "file-saver"
import XLSX from "sheetjs-style"
import csvIcon from "../../assets/img/export-csv-icon.webp"
const ExportExcel = ((exceldata,filename) => {
    const filetype = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const filextension = ".xlsx";
    const exportExcel = async () => {
        
        const excelDataFormatted = exceldata.exceldata.map((item) => {
        
            return {
                "Customer Name": item?.name,
                "Email": item?.email,
                "Phone": item?.phone,
                "Order Placed At": new Date(item?.placed_at).toLocaleDateString("en-US", "America/Los_Angeles"),
                "Order ID": item?.order_id,
                "Order Medium": item?.type,
                "Order Amount": item?.total,
                "10.35% inclusive tax (sales tax)": item?.tax, // Handle NaN cases
                "Platform Fee (pickup delivery)": item?.platform_fee,
                "Applied Discount": parseFloat(item?.discount).toFixed(2),
                "Refund 1": item?.refund_times,
                "Pincode": item?.pincode,
                "Sub Total" : (parseFloat(item?.total) + parseFloat(item?.tax) + parseFloat(item?.discount) + parseFloat(item?.platform_fee)).toFixed(2)
            };
        });        

        console.log("exportExcel called", excelDataFormatted);
        // Check if exceldata is an array
        if (!Array.isArray(excelDataFormatted)) {
            console.error("exceldata is not an array");
            return;
        }
        const ws = XLSX.utils.json_to_sheet(excelDataFormatted);
        const wb = { Sheets : {'data' : ws}, SheetNames : ["data"] };
        const excelbuffer = XLSX.write(wb, { bookType : "xlsx", type : "array" })
        const data = new Blob([excelbuffer], {type : filetype});
        FileSaver.saveAs(data, "Orders Data"+filextension);
    }
    return (
        <>
        <h4 className='export-csv-btn' onClick={() => exportExcel(exceldata.filename)} >Export CSV <span><img src={csvIcon} alt="" /></span></h4>
        </>
    )
})

export default ExportExcel;